<template>
	<div class="mt-3">
		<b-form @submit.prevent="lmOrHawm">

            <div class="row mb-3">
                <div class="col-sm-5 m-auto">
					<div class="input-group mb-3">
						<b-form-input type="text" tabindex="1" autocomplete="off" ref="lm_or_hawm" @keydown.tab="lmOrHawm" autofocus v-model="lm_or_hawm" placeholder="Scan LM-AWB or HAWB"></b-form-input>
						<div class="input-scan">
							<svg tabindex="2" xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
								<path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"></path>
							</svg>
						</div>
					</div>
                </div>
            </div>

			<div v-if="bagdetails.fm_awb" class="row">
				<div class="col-sm-7 m-auto">

					<table class="table table-bordered">
						<tr class="bg-light"><th>HAWB</th><td>{{bagdetails.h_awb}}</td></tr>
						<tr class="bg-light"><th>LM AWB</th><td>{{bagdetails.lm_awb}}</td></tr>
						<tr class="bg-light"><th>Weight</th><td>{{bagdetails.weight}} kg</td></tr>
						<tr class="bg-light"><th>Dimension (cm)</th><td><span v-if="bagdetails.length || bagdetails.height">{{bagdetails.length}} x {{bagdetails.height}} x {{bagdetails.width}}</span></td></tr>
						<tr class="bg-light"><th>Country</th><td>{{bagdetails.country}}</td></tr>
						<tr class="bg-light" v-if="bagdetails.custom_type"><th>Custom Type</th><td>{{bagdetails.custom_type}}</td></tr>
						<tr class="bg-light" v-if="bagdetails.clearance_type"><th>Clearance Type</th><td>{{bagdetails.clearance_type}}</td></tr>
						<tr class="bg-light" v-if="bagdetails.bag_code"><th>Bag Code</th><td>{{bagdetails.bag_code}}</td></tr>
					</table>

					<div class="text-center">
						<b-button variant="warning" @click="debag">De-Bag</b-button>
					</div>

				</div>
			</div>

		</b-form>
	</div>
</template>

<script>
export default {
	name: "Debag",
	title: "Debag",
	data() {
		return {
			lm_or_hawm: '',
			bagdetails: {},
			tempawb: '',
			openBags: this.$store.getters.global.openBags || [],
			closedBags: this.$store.getters.global.closedBags || [],
		}
	},
	methods: {
		async lmOrHawm() {
			try {
				let carrier_id = 0
				const carrierIdRes = await this.axios.post('/carrier-id', {lm_awb: this.lm_or_hawm})
				if(carrierIdRes.data && carrierIdRes.data.success)
				carrier_id = carrierIdRes.data.carrier_id

				if(this.lm_or_hawm.length > 28 && carrier_id != 2 && carrier_id != 7 && carrier_id != 20) {
					this.lm_or_hawm = this.lm_or_hawm.substring(8)
				}
				else if(carrier_id == 20) {
					this.lm_or_hawm = this.lm_or_hawm.substring(8, 22)
				}

				const res = await this.axios.post(`/fetchdetails`, {lmLabel: this.lm_or_hawm})
				if(res.data.success) {
					this.bagdetails = res.data.data
					this.tempawb = this.lm_or_hawm
					this.lm_or_hawm = ''
				} else {
					this.lm_or_hawm = ''
					this.popToast('Alert!', res.data.message, 'danger')
				}
			} catch (err) {
				this.lm_or_hawm = ''
				console.error(err)
			}
		},
		async debag() {
			if(confirm('Are you sure? Want to De-bag this LM-AWB.')) {
				const res = await this.axios.post(`/debag/`, {lmLabel: this.tempawb})
				if(res.data.success) {

					// Find bag from open bag
					let i = this.openBags.findIndex(i => i.bag_code == this.bagdetails.bag_code)
					console.log('open', i)
					if(i>=0) {
						// Update weight and qty then push to store.
						this.openBags[i].weight -= parseFloat(this.bagdetails.weight)
						this.openBags[i].qty--
						this.$store.commit('setGlobal', {'openBags': this.openBags})
					}

					// Find bag from closed bag
					i = this.closedBags.findIndex(i => i.bag_code == this.bagdetails.bag_code)
					console.log('close', i)
					if(i>=0) {
						// Update weight and qty then push to store.
						this.closedBags[i].weight -= parseFloat(this.bagdetails.weight)
						this.closedBags[i].qty--
						if(this.closedBags[i].qty == 0) {
							this.closedBags.splice(i, 1)
						}
						this.$store.commit('setGlobal', {'closedBags': this.closedBags})
					}

					this.bagdetails = {}
					this.tempawb = ''
					this.popToast('Success!', res.data.message, 'success')
				} else {
					this.popToast('Failed!', res.data.message, 'danger')
				}
				this.$refs.lm_or_hawm.focus()
			}
		}
	}
}
</script>